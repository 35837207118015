import { Controller } from "@hotwired/stimulus"
import { enter, leave } from 'el-transition'
import { FetchRequest, get, post } from '@rails/request.js'

const RequestStatusConstants = Object.freeze({
  ACTIVE:     'active',
  ERROR:      'error',
  INACTIVE:   'inactive',
  PROCESSING: 'processing',
  SUCCESS:    'success',
})

const Defaults = Object.freeze({
  ENTITY_CLASS:   '.entity',
  TURBO_STREAM:   'turbo-stream',
  TARGETS:        ['button', 'status']
})

export default class extends Controller {
  static classes = Object.values(RequestStatusConstants)
  static targets = Defaults.TARGETS
  static values = { url: String }

  connect() {
    super.connect()
  }

  get(event) {
    event.preventDefault();

    this.getRequest(this.urlValue)
  }

  getRequest(url) {
    const request = get(url, { responseKind: Defaults.TURBO_STREAM })
    return this.processRequest(request)
  }

  post(event) {
    event.preventDefault();

    let data = new FormData()

    this.postRequest(this.urlValue, data)
  }

  postRequest(url, data) {
    const request = post(url, { body: data, responseKind: Defaults.TURBO_STREAM })
    return this.processRequest(request)
  }

  processRequest(request) {
    // disable button
    this.toggleElementDisabledState(this.buttonTarget)

    return request.then((response) => {
      if (response.ok) {
        this.responseOkPromise()
        return response
      } else {
        this.toggleElementDisabledState(this.buttonTarget)
      }
    })
  }

  responseOkPromise() {
    // toggle button disabled state
    Promise.all([
      this.toggleElementDisabledState(this.buttonTarget)
    ])
  }

  toggleElementDisabledState(el) {
    el.disabled ? el.disabled = false : el.disabled = true;
  }

  disconnect() {
  }
}